
import {defineComponent, ref, watch} from 'vue'
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import PublicFormService from "@/core/services/PublicFormService";
import BaseForm from "@/components/base/form/BaseForm.vue";
import CustomFormField from "@/components/base/custom-form/CustomFormField.vue";

export default defineComponent({
  name: "PublicForm",
  components: {CustomFormField, BaseForm, EntityLoading},
  props: {
    source: {type: String}
  },
  setup(props) {
    const step = ref(0);
    const submitted = ref(false);
    const submitting = ref(false);
    const form = ref<any>({})
    const loading = ref(true);
    const loadForm = (source) => {
      PublicFormService.getForm(source).then(res => {
        console.log(res);
        form.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadForm(props.source)
    watch(() => props.source, cb => {
      loadForm(cb)
    })
    return {
      step,
      submitting,
      submitted,
      loading,
      form,
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      PublicFormService.update(this.form.id, {schema: this.form.schema, data: this.form.data}).then(() => {
        this.submitted = true;
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
